// 录播课程详情
<template>
  <div class="layout-page" v-cloak>
   
    <div class="image-swipt">
         <image-swipe :url='url'></image-swipe>
    </div>
    <!-- 卡片 -->
    <div class="course-card">
      <div class="title-head">
        <van-row>
          <van-col span="22">{{detail.course_name}}</van-col>
          <!-- <van-col span="2" style="text-align:right">
           <div class="photo" > 
              <div class="img" v-for='(item,i) in avater' >
              <img   alt="" class="img" :src='item.member.avatar' src="../../assets/touxiang.png">  
              </div>
            </div>
          </van-col> -->
        </van-row>
      </div>
      <div  style="text-align:right;font-size:0.29rem;color:#CDCDCD" >{{detail.period_total}}课时</div>
      <div class="course-time">
        <van-row>
          <!-- <van-col span="12">{{currentDate}}</van-col> -->
          <van-col span="12"  class="title-head-tag" style="text-align:right" v-if="detail.is_free=='0'">￥{{detail.discount_price}}</van-col>
          <van-col span="12"  class="title-head-tag" style="text-align:right" v-if="detail.is_free=='1'">免费</van-col>
        </van-row>
      </div>
      <!-- <div class="course-time">
        <van-row style="margin-top:0.67rem">
          
             <van-col span="12" v-if="detail.is_free=='0'" style="color:#ff4a26">￥{{detail.discount_price}}</van-col>
              <van-col span="12" v-if="detail.is_free=='1'" style="color:#ff4a26">免费</van-col>
          <van-col span="12" style="text-align:right;font-size:0.32rem;color:#8c98b3">{{detail.period_total}}课时</van-col>
        </van-row>
      </div> -->
    </div>
    <!-- 优惠券 -->
    <div class="coupon" v-for='item in discount' @click='receive(item.id)'  v-if='item.is_receive==0'>
        <div class="money" ><span>￥</span>{{item.discount_amount}}</div>
       <div class="condition">
          <span>满{{ item.meet_amount}}减{{ item.discount_amount}}</span> 
          </br>
         <span>{{item.end_time}}</span> 
       </div>
        <div class="click">点击领取优惠券</div>
    </div>
      <!-- 联报活动 -->
  <!-- <div class="Fullreduction"  v-if='this.lengths!=0'  @click='Fullreduction'>
     <div class="left">课程联报</div>
     <div class="right">参与活动最高减1000</div>
     <img src="../../assets/kcxqjt.png" alt="">
  </div> -->
    <!-- 切换栏 -->
    <div class="tabs">
      <!-- 切换按钮 -->
      <ul class="tab-tilte">
        <li @click="cur=0" :class="{active:cur==0}"> <span></span> <i>课程详情</i></li>
        <li @click="cur=1" :class="{active:cur==1}"> <span></span> <i>课程目录</i></li>
        <!-- <li @click="cur=2" :class="{active:cur==2}"> <span></span> <i>课后练习</i></li> -->
      </ul>
      <div class="tab-content">
        <!-- 课程详情 -->
        <div v-show="cur==0">  
                  <div class="image" style="padding-bottom:1.31rem">
                    <img :src="detail.piiic" alt="" style=" width: 100%;height: 100%;">
                  </div>
        </div>
        <!-- 课程目录 -->
        <div v-show="cur==1" style="padding-bottom:1.31rem">

          <van-collapse v-model="activeNames"  accordion >
                <van-collapse-item v-for='(item,index) in catalogs' :key="item.id" :title="item.title" :name="index" :color='$fff'>
                  
                  <p  class="lists" v-for='s in item.course_video_lesson' :key="s" @click="jump">
                    <img class="bofang" v-if="value.is_buy==1" src="../../assets/bofang111111.png" alt=""/>            
                    <img class="bofang" v-if="value.is_buy==0" src="../../assets/suo11111.png" alt="">
                    {{s.title}} </p>
                  <p style="border-bottom:none;text-align:center"  v-if="item.course_video_lesson==undefined">暂无小节</p>
                </van-collapse-item>
        </van-collapse>


           <!-- <div class="list" v-for='item in catalogs'  @click="jump" >
             <p>{{item.title}}</p>
             <p  class="lists" v-for='s in item.course_video_lesson'> <img src="../../assets/bofang.png" alt=""> {{s.title}} </p>
             <p style="border-bottom:none;text-align:center" v-if="item.course_video_lesson==''">暂无小节</p>
           </div> -->
        </div>
        <!-- 学员评论 -->
        <!-- <div v-show="cur==2">
          <ul class="comment">
            <li v-for='item in comments'>
              <div class="left">
                <img :src="item.member.avatar" alt="">
              </div>
              <div class="right">
                <div class="name">{{item.member.nickname}}</div>
                <p>{{item.content}}</p>
                <div class="times">{{item.created_at}}</div>
              </div>
            </li>                
          </ul>
          <div class="imgs" @click='jump'></div>
        </div>                    -->
      </div>       
    </div>


    <div class="download" v-show="this.download" >
      <div class="box">
        <div class="boxs" v-if='this.aa==false'>是否前往打开（下载）考研通APP？</div>
        <div class="boxs" v-if='this.aa==true'>请点击右上角按钮使用浏览器打开</div>
       
        <div class="bottomlist">
          <div class="leftlist" @click='close'>取消</div>
  <div class="rightlist" @click="open">打开(下载)</div>
        </div>
       
      </div>
  </div>

  <div class="back" v-if="this.download==true"></div>
  <div class="mklos" v-if="this.srcimg==true"><img  class="srcimg" src="../../assets/6285022353_94724262916_b3d861398b827dfef45a9be6450853eb.png" alt=""></div>

  <pay-footer :value='value'></pay-footer>
  </div>

</template>
<script>
import { Dialog } from 'vant';
import request from "../../plugin/request";
import imageSwipe from "@/components/common/imageSwipe";
import payFooter from "@/components/common/footerPay";
import Cookies from 'js-cookie';
import { Toast } from 'vant';
export default {
  components: {
    imageSwipe,
    payFooter
  },
  data() {
    return {
      tokenitem:'',
      srcimg:false,
      download:false,
    aa:false,
      activeNames: '1',
      currentSchooldId:'',
      active: 1,
      //默认选中第一个tab
      cur:0 ,
      // 优惠券
      discounts:'',
      lengths:'',
      // 详情
      detail:'',
      // 评论
      comments:'',
       // 目录
      catalogs:'',
       // 详情时间
       currentDate:'',
       // 图片
      url:'',
        avater:'',
        share_id:'',
        //  传值
      value:{
        // 购买人数
        buy_count:'',
        // 价格
        discount_price:'',
        hidden:'',
        mobile:'',
        wechat_ID:''
      },
    };
  },
    mounted() {
      this.tokenitem = localStorage.getItem('token');
    let currentQuery = this.$route.query;
    this.currentSchooldId = currentQuery.id;
    this.value.hidden = currentQuery.is_hidden;
    this.value.share_id = currentQuery.share_id;
    this.share_id=currentQuery.share_id

    this.setToken()
    this.$nextTick(() => {
      this.details();
      this.comment();
      this.discount();
      this.catalog()
    });
  },
  methods: {

    close() {
      // window.history.back()   
      this.download=false 
      this.aa=false
      this.srcimg=false
    },
      //下载页面
    open() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)=="micromessenger") {
         this.a()
         this.bimg()
      }else{
       try{
       if(navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)){
       window.location.href="kaoyantong://kytapp";
       }else if(navigator.userAgent.match(/android/i)){ 
          window.location.href="zixin://main.app"
       }
       }
     catch(e){
      }
      setTimeout(function(){
        if(navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
          window.location.href="https://itunes.apple.com/cn/app/id1526934426?mt=8"
         }else if(navigator.userAgent.match(/android/i)) {
          window.location.href="https://kytapp.oss-cn-shanghai.aliyuncs.com/app-release.apk"
         }
        },2000);
      }
   },
   a() {
     this.aa=true
   },
   bimg() {
     this.srcimg=true
   },
    Fullreduction() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)=="micromessenger") {
           Toast('点击右上角"···"选择用浏览器打开');  
      } else {
      this.$router.push({ path:'/JointReport?id='+this.id+'&course_id='+this.detail.id+'&url=recordingCourse'})
       }
    },
    async receive(id) {
      const data = await request.post('/app/coupon/receive',{ id:id})
      console.log(data)
      if(data.code==505) {
          this.$router.push({ path:'/login?url=followCourse&id='+this.detail.id})
      } else {
          Toast(data.message);
      }
    },
  setToken(token) {
       var tokens = localStorage.getItem('token');
       console.log(tokens)
       if(tokens!=null) {
          return Cookies.set('Token', tokens)
       } else {
           return Cookies.set('Token', token)
       }
      
    },
    jump(url) {
      window.location.href=url;
    },
    jump() {
       if(this.is_buy==0) {
        Toast('请先报名课程!');  
       } else {
        this.download=true 
        // this.$router.push({ path:'/download'})
       }
    },

    // 详情
   async details(id) {
      const {data} = await request.post('/app/course/details',{ id:this.currentSchooldId,is_h5:1,share_id:this.share_id})
      this.detail=data
      this.url=data.course_image
      this.currentDate=data.start_time.substring(0,10).replace(/-/g,".")+'-'+data.end_time.substring(0,10).replace(/-/g,".")
      this.value.buy_count=data.buy_count
      this.value.discount_price=data.discount_price
      this.avater=data.lecturer
      this.value.mobile=data.mobile
      this.value.wechat_ID=data.wechat_ID
      this.value.id=data.id
      this.value.url='recordingCourse'
      this.value.is_buy=data.is_buy
      this.value.is_free=data.is_free
    this.lengths=data.reduction_course.length
      if(this.lengths>0) {
      this.id=data.reduction_course[0].id

      }

    },
  // 评论
   async comment() {
      const {data:{data}}=  await request.post('/app/course/commentList',{course_id:this.currentSchooldId,pagesize:100,page:1})
      this.comments=data
      console.log(data)
    },
     // 目录
  async catalog() {
   const { data } =  await request.post('/app/course/chapters',{id:this.currentSchooldId})
  //  console.log(data)
      this.catalogs=data
      console.log(data)
  },
  // 优惠券
  async  discount() {
   const {data:{data}} = await  request.post('/app/coupon/lists',{type:0,course_id:this.currentSchooldId,pagesize:1,page:1})
   this.discount=data
    }
  }
};
</script>
<style lang="less" scoped>
.Fullreduction {
  position: relative;
    width: 9.2rem;
    border-radius: 0.3rem;
    //  background-color: #ebeff2;
    // box-shadow:
    //    -3px 0 1px 0px #fff, /*左边阴影*/
    //    0 -3px 1px 0px #fff , /*顶部阴影*/
    //    0 4px 1px -1px #b9bec1, /*底部阴影*/
    //    4px 0 1px -1px  #b9bec1; /*右边阴影*/
box-sizing: border-box;
  margin:0.4rem;
height: 1.19rem;
line-height: 1.19rem;

   .left {
     font-size: .37rem;
     float: left;
     margin-left: .53rem;
   }

   .right {
     font-size: .32rem;
     float: right;
     color: #ff4a26;
     margin-right: .9rem;
   }

   img {
     width: .23rem;
     height: .43rem;
     position: absolute;
     top:50%;
     transform: translateY(-50%);
     right: .49rem;
   }



}
 .imgs {
    position: fixed;
    bottom:1.55rem;
    right: .4rem;
    background: url(../../assets/ckpl.png);
    width: 1.36rem;
    height: 1.36rem;
    background-size: 100% 100%;
  }
  p {
     width: 100%;
     height: 1rem;
     line-height: 1rem;
     font-weight: 400;
    //  border-bottom:1px solid #c7c7c7;
     margin-left:0.8rem;
     font-size: 0.37rem;
font-weight: normal;
color: #3D3D3D;
overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 8.5rem;
   }
.list  {
  width: 100%;
  box-sizing: border-box;
  padding:0 0.53rem;
  // background-color: #ebeff2;
  border-radius:  0.33rem;
  // padding-bottom: 1.31rem;
//   box-shadow:
// -2px 0 5px 0px #fff, /*左边阴影*/
// 0 -2px 5px 0px #fff , /*顶部阴影*/
// 0 4px 6px -1px #b9bec1, /*底部阴影*/
// 4px 0 6px -1px  #b9bec1; /*右边阴影*/
// margin-bottom:0.33rem;

   p {
     width: 100%;
     height: 1rem;
     line-height: 1rem;
     font-size: 0.37rem;
     font-weight: 400;
     border-bottom:1px solid #c7c7c7;
     margin:0;
    
   }

   .lists  {
     position: relative;
     font-size: 0.32rem;
     padding-left: 0.93rem;
     box-sizing: border-box;

     img {
       position: absolute;
       top:50%;
       transform: translateY(-50%);
       left: 0;
       width: 0.59rem;
       height: 0.59rem;
     }
     span {
       display: inline-block;
        height: 0.29rem;
        font-size: 0.32rem;
        padding:3px 5px;
        border:1px solid #317bfe;
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        right: 0.07rem;
        line-height:0.29rem;
        color: #317bfe;
        border-radius:0.09rem;

     }
   } 

    .lists:last-child {
       border-bottom:0
    }
}

.course-card {
  // width: 9.2rem;
  // margin:0.3rem;
  // padding:0.35rem 0.49rem  0.30rem;
padding: 0.53rem 0.4rem 0.43rem 0.4rem;
 border-radius: 0.4rem;
//  background-color: #ebeff2;
    // box-shadow:
    //    -3px 0 1px 0px #fff, /*左边阴影*/
    //    0 -3px 1px 0px #fff , /*顶部阴影*/
    //    0 4px 1px -1px #b9bec1, /*底部阴影*/
    //    4px 0 1px -1px  #b9bec1; /*右边阴影*/
box-sizing: border-box;
}

.title-head  {
  width: 100%;
  height: 0.65rem;
  line-height: 0.65rem;
  font-size: 0.45rem;
  font-weight: 600;
}

.photo  {

  height:0.65rem;
}

.img   {
  width: 0.65rem;
  height: 0.65rem;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 0.16rem;
  float: right;
  img {
    width: 100%;
    height: 100%;
  }
}

.title-head-tag  {
  height: 0.65rem;
  // margin-top:0.13rem;
  line-height: 0.65rem;
  font-size: 0.48rem;
  font-weight: normal;
color: #FF4800;
font-weight: 700;
position: absolute;
right: 0;
}

.course-time  {
  width: 100%;
  height:0.65rem;
  line-height: 0.65rem;
  font-size: 0.37rem;
  margin-top: 0.11rem;
  position: relative;
}

.coupon  {
  width: 9.41rem;
  height: 1.6rem;
  background: url(../../assets/yhq.png);
  background-size: 100% 100% ;
  margin-left: .33rem;
  margin-top:.25rem;
}

.money  {
  width: 2.71rem;
  height: 100%;
  color: #fff;
  line-height:  1.6rem;
  font-size: 0.7rem;
  padding-left: 0.37rem;
  box-sizing: border-box;
  float: left;
}

.money span {
  font-size: 0.48rem;
}

.condition  {
  width: 3.39rem;
  height: 100%;
  float: left;
  font-size: 0.32rem;
  box-sizing: border-box;
  padding-top:.32rem;
  color: #fff;

}

.condition  p  {
  padding:0;
  margin:0
}

.click  {
  height: 0.8rem;
  float: left;
  font-size: 0.35rem;
  color: #fff;
  line-height: 0.8rem;
  border-left: 0.01rem #fff  dashed;
  margin-top:0.27rem;
  box-sizing: border-box;
  padding-left:0.24rem ;

}

.tabs  {
  margin-bottom:1.73rem;
  // margin-top:0.4rem
}

.tab-tilte  {
  width: 100%;
  height: 0.72rem;
  line-height: 0.72rem ;
  box-sizing: border-box;
  padding:0 1.41rem;
  display: flex;
  justify-content:space-around;
  font-size: 0.37rem;
 
li {
  position: relative;
  width:1.81rem ;
  height: 100%;
  text-align: center;
  border-radius:0.13rem;
    // background-color: #ebeff2;

    //  box-shadow:
    //    -3px 0 1px 0px #fff, /*左边阴影*/
    //    0 -3px 1px 0px #fff , /*顶部阴影*/
    //    0 4px 1px -1px #b9bec1, /*底部阴影*/
    //    4px 0 1px -1px  #b9bec1; /*右边阴影*/

  i {
    position: relative;
    font-style: normal;
    z-index: 1;
    font-size: 0.4rem;
font-weight: normal;
color: #CDCDCD;
  }
}
  
 


}

li.active  {
  // box-shadow:inset 0.03rem 0.03rem 0.05rem 0.05rem #b9bec1;
  
  span  {
    position: absolute;
    bottom: 0rem;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 0.72rem;
    height: 0.1rem;
    background-color: #2775ff;
    border-radius: 0.07rem;
  }
  i {
    position: relative;
    font-style: normal;
    z-index: 1;
    font-size: 0.4rem;
font-weight: normal;
color:  #3D3D3D;
;
  }
}

.tab-content  {
  width: 100%;
  margin-top:0.37rem;
  // padding:0 0.4rem;
  box-sizing: border-box;
}

.image  {
  width: 100%;
  background-color: #fff;
  border-radius: 0.33rem;
  overflow: hidden;
    // background-color: #ebeff2;
    // box-shadow:
    //    -3px 0 1px 0px #fff, /*左边阴影*/
    //    0 -3px 1px 0px #fff , /*顶部阴影*/
    //    0 4px 1px -1px #b9bec1, /*底部阴影*/
    //    4px 0 1px -1px  #b9bec1; /*右边阴影*/

img  {
  width: 100%;
}
}

.comment  {
  width: 100%;

  li  {
    width: 100%;
    overflow: hidden;
    margin-top:0.41rem;

    .left  {
      float: left; 
      width: 1.35rem;
      height: 100%;

      img  {
        width: 0.84rem;
        height: 0.84rem;
        background-color: #fff;
        border-radius: 50%;
        margin-left: 50%;
        transform: translateX(-50%);
            overflow: hidden;
      }
    }

    .right  {
      float: right;
      width:7.47rem;
      height: 100%;
      border-bottom:0.01rem solid #8c98b3;
      padding-bottom:0.2rem;

      .name  {
        font-size: 0.27rem;
        font-weight: 600;
      }

      p {
        font-size: 0.29rem;
        line-height: 1.5;
         color: #8c98b3;
      }
      .times {
        text-align: right;
        font-size: 0.27rem;
        color: #8c98b3;
      }
     
    }
  }


}
.comment li:last-child {
   .right  {
     border-bottom:none
   }
}

/deep/.van-collapse-item--border::after {
  border: none;
}
/deep/.van-hairline--top-bottom::after{
  border: none;
}
/deep/.van-cell::after{
  border: none;
}
/deep/.van-collapse-item__content{
  padding: 0;
}
/deep/.van-cell{
  margin-top: 0.4rem;
}



.download {
  width: 100%;
  height: 100%;
  background-color:rgba(0,0,0,0.2);
  z-index:1000;
  .box {
    z-index: 999999;
    position: absolute;
    top:40%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 0.4rem;
    text-align: center;
    width: 6.51rem;
    height: 3.7rem;
    background: #FFFFFF;
    border-radius: 0.13rem 0.13rem 0.13rem 0.13rem;
    opacity: 1;
    .boxs {
      padding: 0.8rem 1.39rem 0.73rem 1.36rem;
      line-height: 1.5;
      border-bottom: 0.03rem solid #F0F0F0;
      box-sizing: border-box;
      font-size: 0.37rem;
    }

    .bottomlist {
      width: 100%;
      height: 1.1rem;
      line-height: 1.1rem ;
      position: absolute;
    bottom: 0;
      .leftlist {
        float: left;
        width: 50%;
        border-right:0.03rem solid #F0F0F0;;
        box-sizing: border-box;
        color: #CDCDCD;
      }

      .rightlist {
        float: left;
        width: 50%;
        color: #308bfe;
      }
    }
  }

}
.back{
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #000;
    opacity: 0.3;
}
.mklos .srcimg{
  width: 4rem;
    height: 4rem;

}
.mklos{
  z-index: 999999;
    position: absolute;
    top: 11.5%;
    right: -15%;
    transform: translate(-50%, -50%);
}
.bofang{
  width: 0.45rem;
  height: 0.45rem;
  position: relative;
  top: 0.1rem;
}
</style>